const data = [
    {
        id: 1,
        event: 'Australian Open',
        date: '14-28 January 2024',
        img: 'https://www.tennisworldusa.org/imge/106039/australian-open-2022-new-health-protocols.webp',
        url: 'https://www.ausopen.com/',
    },
    {
        id: 2,
        event: 'Tomorrowland 2024 Weekend- 1',
        date: '19-21 July 2024',
        img: 'https://images.unsplash.com/photo-1537724841875-c0901308941f?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        url: 'https://www.tomorrowland.com/',
    },{
        id: 3,
        event: 'Tomorrowland 2024 Weekend- 2',
        date: '26-28 July 2024',
        img: 'https://djmag.com/sites/default/files/styles/djm_23_961x540_jpg/public/2023-08/tomorrowland-top-100-festivals_0.jpg?itok=XwQ_HNo9',
        url: 'https://www.tomorrowland.com/',
    },

]


export default data;