import React from 'react'
import ExpCards from '../Components/ExpCards'

const Experiences = () => {
  return (
    <div className='exp-con'>
        <ExpCards />
    </div>
  )
}

export default Experiences